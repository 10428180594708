import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { selectAgentCount, selectAgents } from 'selectors/agents/base'
import {
  selectAgentTicketSearchesForOpenAndCurrentMailbox,
  selectAgentTicketSearchesForClosedAndCurrentMailbox,
  selectAgentTicketSearchesForSnoozedAndCurrentMailbox,
} from 'selectors/agents'
import {
  selectGroupsById,
  fetchingStatusesSelector,
  selectCurrentSortOrder,
} from 'selectors/app'
import {
  selectCurrentUser,
  selectCurrentUserIsAdminOrOwner,
} from 'ducks/currentUser/selectors'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { foldersByIdSelector } from 'selectors/folders/foldersByIdSelector'
import {
  selectGroupTicketSearchesForOpenAndCurrentMailbox,
  selectGroupTicketSearchesForClosedAndCurrentMailbox,
  selectGroupTicketSearchesForSnoozedAndCurrentMailbox,
} from 'selectors/groups'

import { selectLabelsById } from 'selectors/labels/base'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { selectHasAnyMailboxByStates } from 'selectors/mailboxes/selectHasAnyMailboxByStates'
import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import {
  selectCurrentTicketSearchQueryObject,
  selectTicketSearchTotalCountsByQueryId,
  selectTicketSearchOperatorValueMap,
} from 'selectors/search'
import {
  selectLabelTicketSearchesForOpenAndCurrentMailbox,
  selectLabelTicketSearchesForClosedAndCurrentMailbox,
  selectLabelTicketSearchesForSnoozedAndCurrentMailbox,
} from 'selectors/search/labels'
import {
  selectPinnedSearchesWithPinId,
  selectPinnedSearchQueryIds,
} from 'selectors/pinned_searches'
import { selectMailboxAccessList } from 'ducks/accessList/selectors'
import { selectFlag } from 'ducks/flags/selectors'
import { HIDE_BOOK_DEMO_CARD, HIDE_TEAMMATES_CARD } from 'ducks/flags/flagNames'

import {
  constructSearchQueryString,
  constructEncodedSearchQueryString,
  constructSearchQueryId,
  areSearchesEquivalent,
  constructSearchQueryTitle,
} from 'util/search'
import { findAgentById } from 'util/agents'
import { selectIsTestAccount } from './account'

const makeSelectTicketsNavSectionTopList = state => {
  return createSelector(
    selectTicketSearchTotalCountsByQueryId,
    selectCurrentMailboxId,
    selectCurrentTicketSearchQueryObject,
    selectTicketSearchOperatorValueMap,
    selectCurrentUser,
    selectCurrentSortOrder,
    (
      totalCounts,
      mailbox,
      currentSearchObject,
      operatorValueMap,
      currentUser,
      sortOrder
    ) => {
      const unassignedSearch = {
        mailbox,
        state,
        unassigned: true,
        group_unassigned: true,
      }
      const mineSearch = {
        mailbox,
        state,
        assignee: currentUser.id,
      }
      const starredSearch = {
        mailbox,
        state,
        starred: 'all',
      }
      const deletedSearch = {
        is: 'deleted',
      }
      const extraSections = []
      if (state === 'closed')
        extraSections.push({
          label: 'Deleted',
          active: areSearchesEquivalent(
            deletedSearch,
            currentSearchObject,
            operatorValueMap
          ),
          href: `/search/${constructEncodedSearchQueryString(
            deletedSearch,
            operatorValueMap,
            { sortOrder }
          )}`,
          queryId: constructSearchQueryId(deletedSearch, operatorValueMap),
          count: totalCounts[constructSearchQueryId(deletedSearch)],
        })
      return {
        type: 'mine',
        items: [
          {
            label: 'Unassigned',
            active: areSearchesEquivalent(
              unassignedSearch,
              currentSearchObject,
              operatorValueMap
            ),
            href: `/search/${constructEncodedSearchQueryString(
              unassignedSearch,
              operatorValueMap,
              { sortOrder }
            )}`,
            queryId: constructSearchQueryId(unassignedSearch, operatorValueMap),
            count: totalCounts[constructSearchQueryId(unassignedSearch)],
          },
          {
            label: 'Mine',
            active: areSearchesEquivalent(
              mineSearch,
              currentSearchObject,
              operatorValueMap
            ),
            href: `/search/${constructEncodedSearchQueryString(
              mineSearch,
              operatorValueMap,
              { sortOrder }
            )}`,
            queryId: constructSearchQueryId(mineSearch, operatorValueMap),
            count: totalCounts[constructSearchQueryId(mineSearch)],
          },
          {
            label: 'Starred',
            active: areSearchesEquivalent(
              starredSearch,
              currentSearchObject,
              operatorValueMap
            ),
            href: `/search/${constructEncodedSearchQueryString(
              starredSearch,
              operatorValueMap,
              { sortOrder }
            )}`,
            queryId: constructSearchQueryId(starredSearch, operatorValueMap),
            count: totalCounts[constructSearchQueryId(starredSearch)],
          },
        ]
          .concat(extraSections)
          .filter(x => !!x && x.count > 0),
      }
    }
  )
}
const selectOpenTicketsNavSectionTopList = makeSelectTicketsNavSectionTopList(
  'open'
)
const selectClosedTicketsNavSectionTopList = makeSelectTicketsNavSectionTopList(
  'closed'
)
const selectSnoozedTicketsNavSectionTopList = makeSelectTicketsNavSectionTopList(
  'snoozed'
)

const makeSelectTicketsNavSectionGroupList = searchSelector => {
  return createSelector(
    searchSelector,
    selectCurrentTicketSearchQueryObject,
    selectTicketSearchOperatorValueMap,
    selectGroupsById,
    selectCurrentSortOrder,
    (searches, currentSearchObject, operatorValueMap, groups, sortOrder) => {
      return {
        type: 'groups',
        items: searches
          .map(search => {
            const groupId = search.queryObject.assigned_group[0]
            const group = groups[groupId]
            if (!group) return undefined
            return {
              label: (group && group.name) || groupId,
              active: areSearchesEquivalent(
                search.queryObject,
                currentSearchObject,
                operatorValueMap
              ),
              href: `/search/${constructEncodedSearchQueryString(
                search.queryObject,
                operatorValueMap,
                { sortOrder }
              )}`,
              queryId: constructSearchQueryId(
                search.queryObject,
                operatorValueMap
              ),
              count: search.totalCount,
            }
          })
          .filter(x => !!x && x.count > 0),
      }
    }
  )
}
const selectOpenTicketsNavSectionGroupList = makeSelectTicketsNavSectionGroupList(
  selectGroupTicketSearchesForOpenAndCurrentMailbox
)
const selectClosedTicketsNavSectionGroupList = makeSelectTicketsNavSectionGroupList(
  selectGroupTicketSearchesForClosedAndCurrentMailbox
)
const selectSnoozedTicketsNavSectionGroupList = makeSelectTicketsNavSectionGroupList(
  selectGroupTicketSearchesForSnoozedAndCurrentMailbox
)

const makeSelectTicketsNavSectionAgentList = searchSelector => {
  return createSelector(
    selectCurrentUser,
    selectAgents,
    searchSelector,
    selectCurrentMailbox,
    selectCurrentTicketSearchQueryObject,
    selectTicketSearchTotalCountsByQueryId,
    selectTicketSearchOperatorValueMap,
    selectCurrentSortOrder,
    (
      currentUser,
      agents,
      searches,
      mailbox,
      currentSearchObject,
      totalCounts,
      operatorValueMap,
      sortOrder
    ) => {
      const items = searches
        .map(search => {
          const agent = findAgentById(agents, {
            id: search.queryObject.assignee[0],
          })
          if (!agent) return undefined
          return {
            current: agent.id === currentUser.id,
            label: agent.possessiveLabel,
            active: areSearchesEquivalent(
              search.queryObject,
              currentSearchObject,
              operatorValueMap
            ),
            href: `/search/${constructEncodedSearchQueryString(
              search.queryObject,
              operatorValueMap,
              { sortOrder }
            )}`,
            queryId: constructSearchQueryId(
              search.queryObject,
              operatorValueMap
            ),
            count: search.totalCount,
          }
        })
        .filter(x => !!x && !x.current)
      return {
        type: 'agents',
        items: items
          .filter(item => item && item.count > 0)
          .sort((a, b) => {
            if (a.current) return -1
            if (b.current) return 1
            if (a.unassigned) return -1
            if (b.unassigned) return 1
            if (a.label > b.label) return 1
            if (a.label < b.label) return -1
            return 0
          })
          .filter(x => !!x && x.count > 0),
      }
    }
  )
}
const selectOpenTicketsNavSectionAgentList = makeSelectTicketsNavSectionAgentList(
  selectAgentTicketSearchesForOpenAndCurrentMailbox,
  'open'
)
const selectClosedTicketsNavSectionAgentList = makeSelectTicketsNavSectionAgentList(
  selectAgentTicketSearchesForClosedAndCurrentMailbox,
  'closed'
)
const selectSnoozedTicketsNavSectionAgentList = makeSelectTicketsNavSectionAgentList(
  selectAgentTicketSearchesForSnoozedAndCurrentMailbox,
  'snoozed'
)

const makesSelectTicketsNavSectionLabelList = (searchSelector, state) => {
  return createSelector(
    searchSelector,
    selectCurrentTicketSearchQueryObject,
    selectTicketSearchOperatorValueMap,
    selectLabelsById,
    selectCurrentMailboxId,
    selectCurrentSortOrder,
    (
      searches,
      currentSearchObject,
      operatorValueMap,
      labels,
      mailbox,
      sortOrder
    ) => {
      let items = searches
        .map(search => {
          const labelID = search.queryObject.label[0]
          const label = labels[labelID]
          return {
            label: (label && label.name) || labelID,
            active: areSearchesEquivalent(
              search.queryObject,
              currentSearchObject,
              operatorValueMap
            ),
            href: `/search/${constructEncodedSearchQueryString(
              search.queryObject,
              operatorValueMap,
              { sortOrder }
            )}`,
            queryId: constructSearchQueryId(
              search.queryObject,
              operatorValueMap
            ),
            count: search.totalCount,
          }
        })
        .filter(x => !!x && x.count > 0)
      if (items.length > 20) {
        items = items.slice(0, 20)
        items.push({
          label: 'And others',
          href: `/search/${constructEncodedSearchQueryString(
            { state, mailbox },
            operatorValueMap,
            { incomplete: 'tag', sortOrder }
          )}`,
          queryId: constructSearchQueryId({ state, mailbox }, operatorValueMap),
          icon: 'search',
        })
      }
      return {
        type: 'labels',
        items,
      }
    }
  )
}
const selectOpenTicketsNavSectionLabelList = makesSelectTicketsNavSectionLabelList(
  selectLabelTicketSearchesForOpenAndCurrentMailbox,
  'open'
)
const selectClosedTicketsNavSectionLabelList = makesSelectTicketsNavSectionLabelList(
  selectLabelTicketSearchesForClosedAndCurrentMailbox,
  'closed'
)
const selectSnoozedTicketsNavSectionLabelList = makesSelectTicketsNavSectionLabelList(
  selectLabelTicketSearchesForSnoozedAndCurrentMailbox,
  'snoozed'
)

const makeSelectTicketsNavSectionIsLoading = navSection => {
  return createSelector(
    selectCurrentMailboxId,
    fetchingStatusesSelector,
    (currentMailboxId, featchingStatuses) => {
      const searchQueryString = constructSearchQueryString({
        state: navSection,
        mailbox: currentMailboxId,
      })
      return featchingStatuses[`ticketAggregationCounts-${searchQueryString}`]
    }
  )
}
export const selectOpenTicketsNavSectionIsLoading = makeSelectTicketsNavSectionIsLoading(
  'open'
)
export const selectClosedTicketsNavSectionIsLoading = makeSelectTicketsNavSectionIsLoading(
  'closed'
)
export const selectSnoozedTicketsNavSectionIsLoading = makeSelectTicketsNavSectionIsLoading(
  'snoozed'
)

function makeSelectTicketsNavSectionLists(
  selectTopList,
  selectGroupList,
  selectAgentList,
  selectLabelList
) {
  return createSelector(
    selectTopList,
    selectGroupList,
    selectAgentList,
    selectLabelList,
    selectPinnedSearchQueryIds,
    state => state,
    (topList, groupList, agentList, labelList, starredSearches) => {
      const lists = [topList, groupList, agentList, labelList]
      lists.forEach(list => {
        // eslint-disable-next-line no-param-reassign
        list.items = list.items.filter(item => {
          return starredSearches.indexOf(item.queryId) < 0
        })
      })
      return lists
    }
  )
}

export const selectOpenTicketsNavSectionLists = makeSelectTicketsNavSectionLists(
  selectOpenTicketsNavSectionTopList,
  selectOpenTicketsNavSectionGroupList,
  selectOpenTicketsNavSectionAgentList,
  selectOpenTicketsNavSectionLabelList
)

export const selectClosedTicketsNavSectionLists = makeSelectTicketsNavSectionLists(
  selectClosedTicketsNavSectionTopList,
  selectClosedTicketsNavSectionGroupList,
  selectClosedTicketsNavSectionAgentList,
  selectClosedTicketsNavSectionLabelList
)

export const selectSnoozedTicketsNavSectionLists = makeSelectTicketsNavSectionLists(
  selectSnoozedTicketsNavSectionTopList,
  selectSnoozedTicketsNavSectionGroupList,
  selectSnoozedTicketsNavSectionAgentList,
  selectSnoozedTicketsNavSectionLabelList
)

const makeTicketNavSectionSearch = ticketState => {
  return createSelector(
    selectCurrentMailbox,
    selectCurrentSortOrder,
    (mailbox, sortOrder) => {
      return {
        label: `Search all ${ticketState}`,
        href: `/search/${constructEncodedSearchQueryString(
          {
            mailbox: mailbox && mailbox.label,
            state: ticketState,
          },
          {},
          { incomplete: true, sortOrder }
        )}`,
      }
    }
  )
}
export const selectOpenTicketsNavSectionSearch = makeTicketNavSectionSearch(
  'open'
)
export const selectClosedTicketsNavSectionSearch = makeTicketNavSectionSearch(
  'closed'
)
export const selectSnoozedTicketsNavSectionSearch = makeTicketNavSectionSearch(
  'snoozed'
)

export const makeTicketNavSectionCount = ticketState => {
  return createSelector(
    selectTicketSearchTotalCountsByQueryId,
    selectCurrentMailboxId,
    (totalCounts, mailboxId) => {
      const mailboxQuery = mailboxId ? `inbox:${mailboxId} ` : ''
      const queryString = `${mailboxQuery}is:${ticketState}`
      return totalCounts[queryString]
    }
  )
}
export const selectOpenTicketsNavSectionCount = makeTicketNavSectionCount(
  'open'
)
export const selectClosedTicketsNavSectionCount = makeTicketNavSectionCount(
  'closed'
)
export const selectSnoozedTicketsNavSectionCount = makeTicketNavSectionCount(
  'snoozed'
)
export const selectDeletedTicketsNavSectionCount = makeTicketNavSectionCount(
  'deleted'
)

function searchNavItem(search, valueMap, sortOrder = undefined) {
  return {
    label: constructSearchQueryTitle(search, valueMap),
    href: `/search/${constructEncodedSearchQueryString(search, valueMap, {
      sortOrder,
    })}`,
    search,
    queryId: constructSearchQueryId(search),
  }
}

export const selectPinnedSearchesListItems = createSelector(
  selectPinnedSearchesWithPinId,
  selectTicketSearchOperatorValueMap,
  selectCurrentSortOrder,
  (searches, map, sortOrder) => {
    if (!searches) return null
    return searches
      .map(pair => {
        const { search, pinnedSearchId } = pair
        const item = searchNavItem(search, map, sortOrder)
        item.pinnedSearchId = pinnedSearchId
        return item
      })
      .filter(search => !!search)
  }
)

export const selectedPinnedSearchesList = createSelector(
  selectPinnedSearchesListItems,
  items => {
    if (!items) return null
    return {
      type: 'pinned',
      items,
    }
  }
)

export const selectFirstNonZeroPinnedSearch = createSelector(
  selectedPinnedSearchesList,
  starredSearches => {
    return starredSearches.items.find(search => search.count > 0)
  }
)

function encodedQueryUrl(queryObject, valueMap, options = {}) {
  const encodedQuery = constructEncodedSearchQueryString(
    queryObject,
    valueMap,
    options
  )
  return `/search/${encodedQuery}`
}

export const selectKnownMailboxesForSelector = createSelector(
  selectKnownMailboxes,
  selectTicketSearchOperatorValueMap,
  selectTicketSearchTotalCountsByQueryId,
  selectCurrentSortOrder,
  foldersByIdSelector,
  (mailboxes, valueMap, totalCountsByQueryId, sortOrder, foldersById) => {
    const returnMailboxes = []
    if (mailboxes && mailboxes.length > 1) {
      returnMailboxes.push({
        id: null,
        title: `All ${app.t('Mailboxes')}`,
        subTitle: `${mailboxes.length} total`,
      })
    }

    return returnMailboxes
      .concat(
        mailboxes.map(mailbox => {
          return {
            id: mailbox.id,
            title: mailbox.name,
            subTitle: mailbox.email,
            folders: mailbox.folders,
          }
        })
      )
      .map(mailbox => {
        const queryId = constructSearchQueryId(
          { is: 'open', mailbox: mailbox.id },
          valueMap
        )

        return {
          ...mailbox,
          openHref: encodedQueryUrl(
            { is: 'open', mailbox: mailbox.id },
            valueMap,
            { sortOrder }
          ),
          openTicketCount: totalCountsByQueryId[queryId] || null,
          folders:
            mailbox.folders &&
            mailbox.folders.map(folderId => foldersById[folderId]),
        }
      })
  }
)

export const selectKnownMailboxesForDesktopLeftNav = createSelector(
  selectKnownMailboxes,
  selectTicketSearchOperatorValueMap,
  selectCurrentSortOrder,
  foldersByIdSelector,
  selectPrefersUnifiedInbox,
  selectPrefersAllMailboxesSectionVisible,
  (
    mailboxes,
    valueMap,
    sortOrder,
    foldersById,
    prefersUnifiedInbox,
    prefersAllMailboxesSectionVisible
  ) => {
    // In unified inbox mode, we can bail early, as there is only one, unified inbox
    if (prefersUnifiedInbox) {
      return [
        {
          id: null,
          title: 'Conversations',
          subTitle: `${mailboxes.length} total`,
        },
      ]
    }

    const returnMailboxes = mailboxes
      .map(mailbox => {
        return {
          id: mailbox.id,
          title: mailbox.name,
          subTitle: mailbox.email,
          folders: mailbox.folders,
        }
      })
      .map(mailbox => {
        return {
          ...mailbox,
          openHref: encodedQueryUrl(
            { is: 'open', mailbox: mailbox.id },
            valueMap,
            { sortOrder }
          ),
          openTicketCount: null,
          folders:
            mailbox.folders &&
            mailbox.folders.map(folderId => foldersById[folderId]),
        }
      })

    // If the All Mailboxes section is visible, display it
    // However, if the account only has one mailbox, don't as it's redundant
    if (prefersAllMailboxesSectionVisible && returnMailboxes.length > 1) {
      returnMailboxes.unshift({
        id: null,
        title: `All ${app.t('Mailboxes')}`,
        subTitle: `${mailboxes.length} total`,
      })
    }

    return returnMailboxes
  }
)

export const selectDeletedSearch = createSelector(
  selectCurrentMailboxId,
  mailbox => ({
    mailbox,
    deleted: 'all',
  })
)

export const selectDeletedTicketsNavSectionHref = createSelector(
  selectDeletedSearch,
  deletedSearch => `/search/${constructEncodedSearchQueryString(deletedSearch)}`
)

export const selectedBaseStore = state => state.leftnav

export const selectLeftNavMailboxes = createSelector(
  selectedBaseStore,
  store => store.mailboxes
)

export const selectLeftNavFolders = createSelector(
  selectedBaseStore,
  store => store.folders
)

export const selectLeftNavFolderById = createCachedSelector(
  selectLeftNavFolders,
  (_, folderId) => folderId,
  (folders, folderId) => folders?.find(folder => folder.id === folderId)
)((_, folderId) => folderId)

export const selectLeftNavAgents = createSelector(
  selectedBaseStore,
  store => store.agents
)

export const selectLeftNavAgentsForMailboxId = createCachedSelector(
  selectLeftNavAgents,
  selectMailboxAccessList,
  (_, mailboxId) => mailboxId,
  (leftNavAgents, accessLists, mailboxId) => {
    const agentsForMailboxId = accessLists[mailboxId]?.agentIds || []

    // no restrictions if array is empty
    if (!agentsForMailboxId.length) return leftNavAgents
    return leftNavAgents.filter(agent => agentsForMailboxId.includes(agent.id))
  }
)((_, mailboxId) => mailboxId || 'allinboxes')

export const selectLeftNavMailboxById = createCachedSelector(
  selectLeftNavMailboxes,
  selectLeftNavFolders,
  (_, mailboxId) => mailboxId,
  (mailboxes, folders, mailboxId) => {
    const folderById = folders.reduce((fById, folder) => {
      // eslint-disable-next-line no-param-reassign
      fById[folder.id] = folder
      return fById
    }, {})

    const mailbox = mailboxes.find(m => m.id === mailboxId)
    return {
      ...mailbox,
      folders: mailbox.folders.map(folderId => folderById[folderId]),
    }
  }
)((_, mailboxId) => mailboxId || 'allinboxes')

export const selectDoesLeftNavIncludeMailbox = createCachedSelector(
  selectLeftNavMailboxes,
  (_, mailboxId) => mailboxId,
  (mailboxes, mailboxId) => mailboxes.some(m => m?.id === mailboxId)
)((_, mailboxId) => mailboxId || 'unknown')

export const selectLeftNavShowBookDemoCard = createSelector(
  selectCurrentUserIsAdminOrOwner,
  state => selectFlag(state)(HIDE_BOOK_DEMO_CARD),
  selectIsTestAccount,
  (isManager, hideBookDemoCard, isTestAccount) => {
    return isManager && hideBookDemoCard === false && !isTestAccount
  }
)

const confirmedStates = ['active', 'confirmed']
export const selectLeftNavShowTeammatesCard = createCachedSelector(
  selectCurrentUserIsAdminOrOwner,
  selectAgentCount,
  selectMailbox,
  state => selectFlag(state)(HIDE_TEAMMATES_CARD),
  state => selectHasAnyMailboxByStates(state, confirmedStates),
  selectIsTestAccount,
  (
    isManager,
    agentCount,
    mailbox,
    hideTeammatesCard,
    hasAnyMailboxByStates,
    isTestAccount
  ) => {
    if (isTestAccount) return false

    let isMailboxConfirmed = false
    if (mailbox) {
      isMailboxConfirmed = confirmedStates.includes(mailbox.state)
    } else {
      // Unified mailbox
      isMailboxConfirmed = hasAnyMailboxByStates
    }
    return (
      isManager &&
      agentCount === 1 &&
      hideTeammatesCard === false &&
      isMailboxConfirmed
    )
  }
)((_state, mailboxId) => mailboxId || 'unknown')
